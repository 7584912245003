
import {
  computed, defineComponent,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarTipoFrete from '@/components/Compartilhados/SelecionarTipoFrete.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { INotaFiscal, INotaFiscalTransporteVolume } from '@/models/Entidades/Fiscal/INotaFiscal';
import RecebimentoMercadoriasFreteVolume from './NotaFiscalFreteVolume.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import SelecionarTransportadora from '@/components/Cadastros/Pessoas/Transportadoras/SelecionarTransportadora.vue';

export default defineComponent({
  name: 'NotaFiscalFrete',
  props: {
    notaFiscal: {
      type: Object as () => INotaFiscal,
      required: true,
    },
    mostrarOutrosValores: {
      type: Boolean,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    SelecionarTipoFrete,
    CampoNumerico,
    SelecionarTransportadora,
    RecebimentoMercadoriasFreteVolume,
    MensagemSemDados,
  },
  emits: ['update:notaFiscal', 'totalAlterado', 'campoAlterado'],
  setup(props, { emit }) {
    const computedNotaFiscal = computed({
      get: () => props.notaFiscal,
      set: (val: INotaFiscal) => {
        emit('update:notaFiscal', val);
      },
    });

    function totalAlterado() {
      emit('totalAlterado');
    }

    function campoAlterado() {
      emit('campoAlterado');
    }

    function adicionarVolume() {
      const volume = {} as INotaFiscalTransporteVolume;
      computedNotaFiscal.value.transporte.volumes.push(volume);
    }

    function removerVolume(index: number) {
      computedNotaFiscal.value.transporte.volumes.splice(index, 1);
      campoAlterado();
    }

    return {
      props,
      ETipoRelacaoPessoa,
      computedNotaFiscal,
      adicionarVolume,
      campoAlterado,
      removerVolume,
      totalAlterado,
    };
  },
});
