import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import {
  IVenda,
} from '@/models/Entidades/Vendas/IVenda';
import { IParametrosConsultaVenda } from '@/models/ParametrosRequisicao/Vendas/IParametrosConsultaVenda';
import { IRetornoDFe } from '@/core/models/IRetornoDFe';
import { IParametrosConsultaVendaFaturamento } from '@/models/ParametrosRequisicao/Vendas/IParametrosConsultaVendaFaturamento';
import { IDTOFaturamento } from '@/models/DTO/Fiscal/IDTOFaturamento';
import { IDTOFaturamentoTotalizadorStatus } from '@/models/DTO/Fiscal/IDTOFaturamentoTotalizadorStatus';
import { INotaFiscal } from '@/models/Entidades/Fiscal/INotaFiscal';

/**
 * Serviço de Painel de Vendas
 * Fornece todas regras de negócios e lógicas relacionado a painel de vendas.
 */
class ServicoFaturamento {
  endPoint = 'faturamentos';

  apiERP = new ApiERP();

  public requisicaoSistema(): void {
    this.apiERP = new ApiERP(true);
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(`${this.endPoint}/vendas`);
    return listaPropriedades;
  }

  public async buscaAvancada(processo: number, parametrosConsulta: IParametrosConsultaVenda, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    let parametrosAdicionais = '';
    if (parametrosConsulta.empresas !== undefined && parametrosConsulta.empresas.length > 0) {
      parametrosConsulta.empresas.forEach((codigoEmpresa) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Empresas=${codigoEmpresa}`;
      });
    }

    if (parametrosConsulta.periodo !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;

      parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
      parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
    }

    if (parametrosConsulta.valorBuscaRapida !== undefined) {
      if (UtilitarioGeral.valorValido(parametrosConsulta.valorBuscaRapida)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `valorBuscaRapida=${parametrosConsulta.valorBuscaRapida}`;
      }
    }

    if (filtros !== undefined) {
      const jsonFiltros = JSON.stringify(filtros);
      parametrosAdicionais += `&Filtros=${jsonFiltros}`;
    }
    if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
      parametrosAdicionais += `&NumeroPagina=${parametrosConsulta.numeroPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
      parametrosAdicionais += `&QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
      parametrosAdicionais += `&QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
    }
    if (parametrosConsulta.ordenacao !== undefined) {
      parametrosConsulta.ordenacao.forEach((item) => {
        parametrosAdicionais += `&Ordenacao=${item}`;
      });
    }
    if (parametrosConsulta.codigosSelecionados !== undefined) {
      parametrosConsulta.codigosSelecionados.forEach((codigo) => {
        parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
      });
    }

    const result: any = await this.apiERP.get(`${this.endPoint}/vendas/processo/${processo}${parametrosAdicionais}`);
    const listaPaginada = {} as IListaPaginada;
    listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
    listaPaginada.dados = result.data;
    return listaPaginada;
  }

  public async obterFaturamentos(parametrosConsulta: IParametrosConsultaVendaFaturamento, filtros?: IFiltroGenerico[]): Promise<IDTOFaturamento[]> {
    let parametrosAdicionais = '';
    if (parametrosConsulta.empresas !== undefined && parametrosConsulta.empresas.length > 0) {
      parametrosConsulta.empresas.forEach((codigoEmpresa) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Empresas=${codigoEmpresa}`;
      });
    }

    if (parametrosConsulta.movimentosComerciais !== undefined && parametrosConsulta.movimentosComerciais.length > 0) {
      parametrosConsulta.movimentosComerciais.forEach((codigoTipoMovimento) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `MovimentosComerciais=${codigoTipoMovimento}`;
      });
    }

    if (parametrosConsulta.periodo !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;
      parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
      parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
    }

    if (parametrosConsulta.status !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `status=${parametrosConsulta.status}`;
    }

    if (parametrosConsulta.valorBuscaRapida !== undefined) {
      if (UtilitarioGeral.valorValido(parametrosConsulta.valorBuscaRapida)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `valorBuscaRapida=${parametrosConsulta.valorBuscaRapida}`;
      }
    }

    if (filtros !== undefined) {
      const jsonFiltros = JSON.stringify(filtros);
      parametrosAdicionais += `&Filtros=${jsonFiltros}`;
    }
    if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
      parametrosAdicionais += `&NumeroPagina=${parametrosConsulta.numeroPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
      parametrosAdicionais += `&QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
      parametrosAdicionais += `&QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
    }
    if (parametrosConsulta.ordenacao !== undefined) {
      parametrosConsulta.ordenacao.forEach((item) => {
        parametrosAdicionais += `&Ordenacao=${item}`;
      });
    }
    if (parametrosConsulta.codigosSelecionados !== undefined) {
      parametrosConsulta.codigosSelecionados.forEach((codigo) => {
        parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
      });
    }

    const result: any = await this.apiERP.get(`${this.endPoint}${parametrosAdicionais}`);
    return result.data;
  }

  public async obterTotalizadores(empresa:number): Promise<IDTOFaturamentoTotalizadorStatus[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/totalizadores/empresa/${empresa}`);
    return result.data;
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: IVenda): string {
    return String(objeto.codigo);
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
    const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    const result: any = await this.apiERP.get(`${this.endPoint}/vendas/consulta-rapida${parametrosAdicionais}`);
    return result.data;
  }

  public async obterVendaFaturamento(venda: number, empresa: number): Promise<IVenda> {
    const result: any = await this.apiERP.get(`${this.endPoint}/venda/${venda}/empresa/${empresa}`);
    return result.data;
  }

  public async obterDadosNotaFiscalFaturamento(codigoNotaFiscal: number, empresa: number): Promise<INotaFiscal> {
    const result: any = await this.apiERP.get(`${this.endPoint}/nota-fiscal/${codigoNotaFiscal}/empresa/${empresa}`);
    return result.data;
  }

  public async cancelar(venda: number, processo: number, empresa: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/venda/cancelar/${venda}/processo/${processo}/empresa/${empresa}`);
    return result.data;
  }

  public async faturar(faturamento:IDTOFaturamento, empresa:number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}/empresa/${empresa}`, faturamento);
    return result.data;
  }

  public async emitirDocumentoFiscal(faturamento:IDTOFaturamento, empresa:number): Promise<IRetornoDFe> {
    const result: any = await this.apiERP.post(`${this.endPoint}/documento-fiscal/empresa/${empresa}`, faturamento);
    return result.data;
  }
}
export default ServicoFaturamento;
