import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-566608bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ant-row"
}
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_6 = {
  key: 1,
  class: "ant-row"
}
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-20" }
const _hoisted_8 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_9 = { class: "ant-row" }
const _hoisted_10 = { class: "ant-col ant-col-xs-24 ant-col-xl-14" }
const _hoisted_11 = { class: "ant-input-group-wrapper" }
const _hoisted_12 = { class: "ant-input-wrapper ant-input-group" }
const _hoisted_13 = {
  class: "ant-input-group-addon",
  style: {"padding":"0px","background-color":"transparent","border":"none!important"}
}
const _hoisted_14 = { class: "ant-col ant-col-xs-10 ant-col-xl-4" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "ant-col ant-col-xs-14 ant-col-xl-3" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = { class: "ant-col ant-col-xs-10 ant-col-xl-3" }
const _hoisted_19 = ["disabled"]
const _hoisted_20 = { class: "ant-row" }
const _hoisted_21 = {
  class: "ant-col ant-col-xs-24 ant-col-xl-24",
  style: {"padding-top":"5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_tipo_frete = _resolveComponent("selecionar-tipo-frete")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_icone = _resolveComponent("icone")!
  const _component_selecionar_transportadora = _resolveComponent("selecionar-transportadora")!
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_recebimento_mercadorias_frete_volume = _resolveComponent("recebimento-mercadorias-frete-volume")!
  const _component_card = _resolveComponent("card")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_form, { layout: "vertical" }, {
      default: _withCtx(() => [
        (_ctx.props.mostrarOutrosValores)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_a_form_item, {
                  label: "Tipo de Frete",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_selecionar_tipo_frete, {
                      valor: _ctx.computedNotaFiscal.tipoFrete,
                      "onUpdate:valor": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedNotaFiscal.tipoFrete) = $event)),
                      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.campoAlterado())),
                      disabled: !_ctx.props.editavel
                    }, null, 8, ["valor", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_form_item, {
                  label: "Valor do Frete",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      monetario: true,
                      quantidadeCasasDecimais: 2,
                      valor: _ctx.computedNotaFiscal.totalFrete,
                      "onUpdate:valor": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedNotaFiscal.totalFrete) = $event)),
                      disabled: !_ctx.props.editavel,
                      onValorDigitado: _cache[3] || (_cache[3] = ($event: any) => (_ctx.totalAlterado()))
                    }, null, 8, ["valor", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_a_form_item, {
                  label: "Seguro",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      monetario: true,
                      quantidadeCasasDecimais: 2,
                      valor: _ctx.computedNotaFiscal.totalSeguro,
                      "onUpdate:valor": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedNotaFiscal.totalSeguro) = $event)),
                      disabled: !_ctx.props.editavel,
                      onValorDigitado: _cache[5] || (_cache[5] = ($event: any) => (_ctx.totalAlterado()))
                    }, null, 8, ["valor", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_a_form_item, {
                  label: "Outras Despesas",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      monetario: true,
                      quantidadeCasasDecimais: 2,
                      valor: _ctx.computedNotaFiscal.totalOutrasDespesas,
                      "onUpdate:valor": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedNotaFiscal.totalOutrasDespesas) = $event)),
                      disabled: !_ctx.props.editavel,
                      onValorDigitado: _cache[7] || (_cache[7] = ($event: any) => (_ctx.totalAlterado()))
                    }, null, 8, ["valor", "disabled"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_a_form_item, {
                  label: "Tipo de Frete",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_selecionar_tipo_frete, {
                      valor: _ctx.computedNotaFiscal.tipoFrete,
                      "onUpdate:valor": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedNotaFiscal.tipoFrete) = $event)),
                      onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.campoAlterado())),
                      disabled: !_ctx.props.editavel
                    }, null, 8, ["valor", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_a_form_item, {
                  label: "Valor do Frete",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      monetario: true,
                      quantidadeCasasDecimais: 2,
                      valor: _ctx.computedNotaFiscal.totalFrete,
                      "onUpdate:valor": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.computedNotaFiscal.totalFrete) = $event)),
                      disabled: !_ctx.props.editavel,
                      onValorDigitado: _cache[11] || (_cache[11] = ($event: any) => (_ctx.totalAlterado()))
                    }, null, 8, ["valor", "disabled"])
                  ]),
                  _: 1
                })
              ])
            ])),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, [
                  _createVNode(_component_icone, {
                    class: "ss-icone",
                    style: 'color: #a0aec1;padding: 8px;background-color: #ebeff2;border-radius: 3px;',
                    nome: "caminhao"
                  })
                ]),
                _createVNode(_component_a_form_item, {
                  label: "Transportadora",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_selecionar_transportadora, {
                      empresas: [_ctx.notaFiscal.codigoEmpresa],
                      codigoSelecionado: _ctx.computedNotaFiscal.transporte.codigoTransportadora,
                      "onUpdate:codigoSelecionado": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.computedNotaFiscal.transporte.codigoTransportadora) = $event)),
                      disabled: !_ctx.props.editavel,
                      onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.campoAlterado()))
                    }, null, 8, ["empresas", "codigoSelecionado", "disabled"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_a_form_item, {
              label: "RNTC",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "ant-input",
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.computedNotaFiscal.transporte.rntc) = $event)),
                  disabled: !_ctx.props.editavel,
                  onChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.campoAlterado()))
                }, null, 40, _hoisted_15), [
                  [_vModelText, _ctx.computedNotaFiscal.transporte.rntc]
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_a_form_item, {
              label: "UF do Veículo",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "ant-input",
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.computedNotaFiscal.transporte.ufPlaca) = $event)),
                  disabled: !_ctx.props.editavel,
                  onChange: _cache[17] || (_cache[17] = ($event: any) => (_ctx.campoAlterado())),
                  maxlength: "2"
                }, null, 40, _hoisted_17), [
                  [_vModelText, _ctx.computedNotaFiscal.transporte.ufPlaca]
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_a_form_item, {
              label: "Placa do Veículo",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "ant-input",
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.computedNotaFiscal.transporte.placaVeiculo) = $event)),
                  disabled: !_ctx.props.editavel,
                  onChange: _cache[19] || (_cache[19] = ($event: any) => (_ctx.campoAlterado()))
                }, null, 40, _hoisted_19), [
                  [_vModelText, _ctx.computedNotaFiscal.transporte.placaVeiculo]
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_card, { titulo: "VOLUMES" }, {
              default: _withCtx(() => [
                (_ctx.computedNotaFiscal.transporte.volumes.length == 0)
                  ? (_openBlock(), _createBlock(_component_mensagem_sem_dados, {
                      key: 0,
                      mensagem: "Nenhum volume adicionado."
                    }))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.computedNotaFiscal.transporte.volumes, (volume, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        style: {"padding-top":"7px"},
                        key: index
                      }, [
                        _createVNode(_component_recebimento_mercadorias_frete_volume, {
                          volume: _ctx.computedNotaFiscal.transporte.volumes[index],
                          "onUpdate:volume": ($event: any) => ((_ctx.computedNotaFiscal.transporte.volumes[index]) = $event),
                          editavel: _ctx.props.editavel,
                          onExcluir: ($event: any) => (_ctx.removerVolume(index))
                        }, null, 8, ["volume", "onUpdate:volume", "editavel", "onExcluir"])
                      ]))
                    }), 128)),
                _createElementVNode("button", {
                  class: "ant-btn ant-btn-secondary ss-acao-destaque-secundaria",
                  onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.adicionarVolume()))
                }, "Inserir novo")
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    })
  ]))
}